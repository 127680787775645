<template>
  <ButtonStock class="textCenter w100 mb-10" title="Создать профиль МПК" @click="addMpk" />

  <ButtonStock class="textCenter w100 mb-10" title="Организовать новую МПК" @click="addEventMpk" />

  <ButtonStock class="textCenter w100 mb-10" title="Создать мероприятие" @click="addEvent" />

  <ButtonStock class="textCenter w100 mb-10" title="Создать карточку проекта" @click="addProject" />
</template>

<script>
  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  export default {
    name: 'Widget1',
    components: {
      ButtonStock,
    },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    methods: {
      addMpk() {
        this.$router.push({
          name: 'CommissionCard',
          params: { id: 0, type: 'mp', action: 'edit' },
          query: { countryId: this.countryId },
        });
      },
      addEventMpk() {
        this.$router.push({ name: 'EventCardAdd', query: { countryId: this.countryId, eventTypeId: 1 } });
      },
      addEvent() {
        this.$router.push({ name: 'EventCardAdd', query: { countryId: this.countryId } });
      },
      addProject() {
        this.$router.push({ name: 'EditProjectCard', params: { id: 0 }, query: { countryId: this.countryId } });
      },
    },
    data() {
      return {};
    },
    created() {},
  };
</script>

<style lang="scss" scoped></style>
